/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/diasfs/jquery.passive@3.4.1/jquery-3.4.1.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /gh/studiogt/scripts@1.0.0/sgt.min.js
 * - /gh/studiogt/scripts@1.0.4/validate.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
